import { Wrapper } from "@googlemaps/react-wrapper";
import { Button, CircularProgress, Divider, Paper, Skeleton, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useRef, useEffect } from "react";
import Layout from "../../components/shared-layout";
import Map from "../../components/map-wrapper";
import MapMarker from "../../components/map-marker";
import { googleMapApiKey } from "../../constants/settings";
import { observer } from "mobx-react";
import useStores from "../../hooks/use-stores";
import VmTable from "../../components/shared-table";
import VmModal from "../../components/shared-modal";
import VmButton from "../../components/shared-button";
import { t } from "i18next";
import ContentLayout from "../../components/shared-content-layout";
import { TD_FIRST, TD_LAST, TD_NORMAL } from "../../constants/style";

const VisitListPage = observer(() => {
  const { groupStore } = useStores();
  const [selectedOrg, setSelectedOrg] = useState<number>(0);
  const [selectedVisit, setSelectedVisit] = useState<any>();
  const [clearWindow, setClearWindow] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<any>(new Date(
    new Date().setMonth(new Date().getMonth() - 6)).toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState<any>(new Date().toISOString().split('T')[0]);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    onToggleVisitList();
  }, []);

  const onToggleVisitList = () => {
    groupStore.getVisitListByDateRange({
      startDate: startDate + "T00:00:00",
      endDate: endDate + "T00:00:00"
    }).then((res) => {
      if (res.length > 0) setSelectedOrg(res[0].id);
    });
  }

  return (
    <ContentLayout
      pageName={t('VISITS_BY_ORG')}
      pageHeading={t('VISITS_BY_ORG')}
      breadCrumb={[]}
      buttonLabel={t('CLEAR_ALL_MAKER')}
      onClickAction={() => setClearWindow(true)}
    >
      <div className="grid grid-cols-5 gap-4">
        <TextField
          value={startDate}
          label={t('START_DATE')}
          type="date"
          variant="outlined"
          sx={{ width: '100%', marginBottom: 2, background: 'white' }}
          className="col-span-2"
          onChange={(value) => setStartDate(value.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={endDate}
          label={t('END_DATE')}
          type="date"
          variant="outlined"
          sx={{ width: '100%', marginBottom: 2, background: 'white' }}
          className="col-span-2"
          onChange={(value) => setEndDate(value.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          variant="outlined"
          sx={{ marginBottom: 2 }}
          disabled={new Date(startDate) > new Date(endDate)}
          onClick={() => onToggleVisitList()}
        >
          {t('APPLY')}
        </Button>
      </div>
      <div className="grid grid-cols-2 gap-2" style={{ minHeight: 500 }}>
        <Box>
          <Wrapper apiKey={googleMapApiKey}>
            {groupStore.loading ? <Skeleton variant="rectangular" sx={{ width: '100%' }} height={500} />
              : <Map
                center={{ lat: -30.363, lng: 151.044 }}
                zoom={5}
                style={{ flexGrow: "1", height: "100%" }}
              >
                {groupStore.visitInDateRangeList.length > 0 &&
                  groupStore.visitInDateRangeList.map((marker: any) => (
                    <MapMarker
                      position={{ lat: marker.latitude, lng: marker.longitude }}
                      popOutlabel={marker}
                      onClick={() => {
                        setClearWindow(false);
                        setSelectedOrg(marker.id);
                      }}
                      clearWindow={clearWindow}
                    />
                  ))}
              </Map>
            }
          </Wrapper>
        </Box>
        <Box sx={{ height: 500, overflow: 'auto' }}>
          {groupStore.loading ? <>
            <div className="text-center mb-4"><Skeleton sx={{ width: '50%' }} /></div>
            <div className="text-center my-4"><Skeleton /></div>
            <div className="text-center my-4"><Skeleton /></div>
            <div className="text-center my-4"><Skeleton /></div>
            <div className="text-center my-4"><Skeleton /></div>
            <div className="text-center my-4"><Skeleton /></div>
          </> : <>
            <Typography variant={"h6"} mb={1}>{groupStore.visitInDateRangeList.length > 0 && selectedOrg != 0 &&
              groupStore.visitInDateRangeList.find(v => v.id == selectedOrg).name}</Typography>
            <VmTable
              loading={groupStore.loading}
              thead={["ID", t('VISIT_NAME'), t('START_TIME'), t('ACTION')]}
              tbody={groupStore.visitInDateRangeList.length > 0 && selectedOrg != 0
                && groupStore.visitInDateRangeList.find(o => o.id == selectedOrg).organisationVisits
                  .map((visit, index) => (
                    <>
                      <Box marginY={1} />
                      <tr key={`visit_${index}`}>
                        <td className={TD_FIRST}>{index + 1}</td>
                        <td className={TD_NORMAL}>{visit.name}</td>
                        <td className={TD_NORMAL}>{visit.startTime.split('T')[0] + " " + visit.endTime.split('T')[1]}</td>
                        <td className={TD_LAST}>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setShowModal(true);
                              setSelectedVisit(visit);
                            }}
                          >
                            {t('VIEW')}
                          </Button>
                        </td>
                      </tr>
                      <Box marginY={1} />
                    </>
                  ))}
            />
          </>}
        </Box>
      </div>

      <VmModal
        width={400}
        openModal={showModal && groupStore.visitInDateRangeList.length > 0 && selectedOrg != 0 && selectedVisit}
        onClose={() => {
          setShowModal(false);
          setSelectedVisit(null);
        }}
        title={showModal && selectedVisit.name}
        showButton={false}
      >
        {showModal &&
          <>
            <table style={{ marginTop: -20, width: '100%' }}>
              <thead><th /><th /></thead>
              <tbody>
                <tr>
                  <td className="font-bold">{t('Location')}: </td>
                  <td className="text-right">{selectedVisit.finalOrganisation.name}</td>
                </tr>
                <tr>
                  <td className="font-bold">{t('STUDENT_SINGLE')}: </td>
                  <td className="text-right">{selectedVisit.studentName}</td>
                </tr>
                <tr>
                  <td className="font-bold">{t('CREATE_TIME')}: </td>
                  <td className="text-right">{selectedVisit.createTime.split('T')[0] + " " + selectedVisit.createTime.split('T')[1]}</td>
                </tr>
                <tr>
                  <td className="font-bold">{t('UPDATED_TIME')}: </td>
                  <td className="text-right">{selectedVisit.updateTime.split('T')[0] + " " + selectedVisit.updateTime.split('T')[1]}</td>
                </tr>
                <tr>
                  <td className="font-bold">{t('START_TIME')}: </td>
                  <td className="text-right">{selectedVisit.finalStartTime.split('T')[0] + " " + selectedVisit.finalStartTime.split('T')[1]}</td>
                </tr>
                <tr>
                  <td className="font-bold">{t('END_TIME')}: </td>
                  <td className="text-right">{selectedVisit.finalEndTime.split('T')[0] + " " + selectedVisit.finalEndTime.split('T')[1]}</td>
                </tr>
                <tr>
                  <td className="font-bold">Responsible Trainer Name: </td>
                  <td className="text-right">{selectedVisit.responsibleTrainerName}</td>
                </tr>
                <tr>
                  <td className="font-bold">Conduct Trainer Name: </td>
                  <td className="text-right text-highlight">{selectedVisit.conductTrainerName}</td>
                </tr>
                <tr>
                  <td className="font-bold">{t('DESCRIPTION')}: </td>
                  <td className="text-right">{selectedVisit.description}</td>
                </tr>
              </tbody>
            </table>
          </>}
      </VmModal>
    </ContentLayout >
  );
});

export default VisitListPage;
